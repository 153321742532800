<template>
  <Layout>
    <template v-slot:label>
      {{ row.label }}
    </template>

    <template v-slot:extra>
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <div class="mt-2 block">
        <template v-for="(label, value) in row.options">
          <div class="radio-input mb-2">
            <label class="inline-flex items-center cursor-pointer">
              <span class="mr-3 text-gray-500">{{ label }}</span>
              <input
                type="radio"
                class="form-radio text-green-600 text-base"
                :name="keypath"
                value="value"
              >
            </label>
          </div>
        </template>
      </div>
    </template>
  </Layout>
</template>
<script>
const Layout = () => import('./Layout')

export default {
  name: 'RadioInput',

  components: {
    Layout
  },

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.row.value
    }
  }
}

</script>
